import './App.css';

function App() {
  return (
    <div className="flex flex-col items-center h-screen w-screen relative bg-[#B3BF99]">
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <div className="w-full md:w-2/5 aspect-square">
          <div className="text-[0px] h-full w-full bg-contain bg-[url('./logo.png')] ">
            Palace Road
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
